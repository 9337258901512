import attackImage from '../Images/attack.png';
import defendImage from '../Images/defend.png';
import { Component } from 'react';

class Counter extends Component {
	//ao adicionar um construtor a uma classe que herda de Component, temos de passar sempre os props
	//e invocar o construtor do Component (classe pai usando o super)
	constructor(props) {
		super(props);

		// this.handleAttack = this.handleAttack.bind(this);
		// this.handleDefense = this.handleDefense.bind(this);
		// this.handleRandomPlay = this.handleRandomPlay.bind(this);

		this.state = {
			count: 0,
			lastPlay: undefined,
			gameStatus: undefined,
			canPlay: true,
		};
	}

	handleAttack = () => {
		//alert('Attack');
		//this.state.count = 2; //cannot change state directly

		//this.setState({ count: 2 }); //este this refere-se à função handleAttack
		//terei de indicar o binding correto no construtor usando o .bind(this)
		//this.setState({ count: this.state.count + 1 });

		// //NOTA: o setState é assincrono e retorna de imediato.
		// //ao invocar a primeira função, ele lê o estado inicial do contador para construir o novo objecto de estado
		// //no entanto retorna de imediato sem que tenha tido tempo de atualizar o estado.
		// //entretanto a segunda função irá ter em consideração o estado inicial, incrementando apenas 10, e será esse o valor final
		// //do contador/estado
		// this.setState({ count: this.state.count + 100 });
		// this.setState({ count: this.state.count + 10 });

		// //para estes casos teremos de usar um setState diferente
		// this.setState((previousState) => {
		// 	return { count: previousState.count + 100 };
		// });
		// this.setState((previousState) => {
		// 	return { count: previousState.count + 10 };
		// });

		if (this.state.canPlay === true)
			this.setState((previousState) => {
				let newCount = previousState.count + Math.round(Math.random() * 10);
				let canPlay = this.userCanPlayAgain(newCount);
				let gameStatus = this.computeGameStatus(newCount);
				return {
					count: newCount,
					lastPlay: 'Ataque',
					gameStatus: gameStatus,
					canPlay: canPlay,
				};
			});
	};

	handleDefense = () => {
		//alert('Defend');
		//this.setState({ count: this.state.count - 1 });
		if (this.state.canPlay === true)
			this.setState((previousState) => {
				let newCount = previousState.count - Math.round(Math.random() * 10);
				let canPlay = this.userCanPlayAgain(newCount);
				let gameStatus = this.computeGameStatus(newCount);
				return {
					count: newCount,
					lastPlay: 'Defesa',
					gameStatus: gameStatus,
					canPlay: canPlay,
				};
			});
	};

	handleRandomPlay = () => {
		let useAttack = Math.round(Math.random()); //menor que 0.5 arredonda para 0
		if (useAttack === 0) this.handleDefense();
		else this.handleAttack();
	};

	handleReset = () => {
		this.setState({
			count: 0,
			lastPlay: undefined,
			gameStatus: undefined,
			canPlay: true,
		});
	};

	userCanPlayAgain = (newCount) => {
		return newCount >= -10 && newCount <= 10;
	};

	computeGameStatus = (newCount) => {
		if (newCount < -10) return 'Derrota';
		else if (newCount > 10) return 'Vitória';
		else return undefined;
	};

	render() {
		return (
			<div className='row text-white text-center'>
				<h1>Pontuação: {this.state.count}</h1>
				<p>Ganhas quando atingires 10 pontos e perdes quando atingires -10!</p>
				<p>Última jogada: {this.state.lastPlay ?? '-'}</p>
				<h3>Estado do jogo: {this.state.gameStatus ?? '-'}</h3>

				<div className='col-6 col-md-3 offset-md-3'>
					<img
						className='p-4 rounded'
						style={{
							width: '100%',
							cursor: 'pointer',
							border: '1px solid green',
						}}
						src={attackImage}
						onClick={this.handleAttack}
					/>
				</div>

				<div className='col-6 col-md-3'>
					<img
						className='p-4 rounded'
						style={{
							width: '100%',
							cursor: 'pointer',
							border: '1px solid red',
						}}
						src={defendImage}
						onClick={this.handleDefense}
					/>
				</div>

				<div className='col-12 col-md-4 offset-md-4'>
					<button
						className='btn btn-secondary w-100 mt-2'
						onClick={this.handleRandomPlay}
					>
						Jogada aleatória
					</button>
					<br />
					<button
						className='btn btn-warning w-100 mt-2'
						onClick={this.handleReset}
					>
						Recomeçar
					</button>
				</div>
			</div>
		);
	}
}

export default Counter;
