import React from 'react';
import ReactDOM from 'react-dom/client';
import Counter from './Components/Counter';
import Header from './Components/Header';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div style={{backgroundColor:"black", color:"grey"}}>
    <Header />
      <div className='px-4'>
        <Counter/>
      </div>   
    </div>
  </React.StrictMode>
);